import React, { Component } from 'react'
import { Carousel } from 'react-bootstrap'

export default class Sliders extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-8 col-md-offset-2">
                        <Carousel>
                            {Array(6).fill(1).map((el,i) => {
                                return <Carousel.Item key={"item-"+i}>
                                    <img width={900} height={500} alt="900x500" src={require('./../assets/images/sliders/slider'+i+'.jpg')} />
                                </Carousel.Item>
                            })}
                        </Carousel>
                    </div>
                </div>
            </div>
        )
    }
}
