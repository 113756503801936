import React, { Component } from 'react'
import '../styles/services.css'

export default class Services extends Component {
    render() {
        return (
            <div className="container margin-top-20">
                <div className="row">
                    <div className="col-xs-12 col-md-4 service service">
                        <div className="wrapper">
                            <figure className="img-indent">
                                <img src={require('../assets/images/service1.png')} alt="" />
                            </figure>
                            <div className="extra-wrap">
                                <h4>Ajuste de Motor</h4>
                                <p className="p2">Si su motor emite humo o presenta sonidos al encender.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-4 service">
                        <div className="wrapper">
                            <figure className="img-indent">
                                <img src={require('../assets/images/service2.png')} alt="" />
                            </figure>
                            <div className="extra-wrap">
                                <h4>Suspensión y Frenos</h4>
                                <p className="p2">Ponga su seguridad en nuestras manos.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-4 service">
                        <div className="wrapper">
                            <figure className="img-indent">
                                <img src={require('../assets/images/service3.png')} alt="" />
                            </figure>
                            <div className="extra-wrap">
                                <h4>Reemplazo de Fluidos</h4>
                                <p className="p2">Cambios de aceite y revisión de niveles.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
