import './../styles/header.css'

import React, { Component } from 'react'
import { Navbar } from 'react-bootstrap'

export default class Topbar extends Component {
  render() {
    return (
      <div>
        <div className="visible-xs">
          <Navbar inverse collapseOnSelect fixedTop>
            <Navbar.Header>
              <Navbar.Brand>
                <a href="/" className="navbar-brand">
                  <img
                    alt="Auto Express 79"
                    src={require('../images/logo.png')}
                    className="logo"
                  />
                </a>
              </Navbar.Brand>
              {/* <Navbar.Toggle /> */}
            </Navbar.Header>
            {/* <Navbar.Collapse>
              <Nav>
                <NavItem eventKey={1} href="#">
                  Link
                </NavItem>
                <NavItem eventKey={2} href="#">
                  Link
                </NavItem>
              </Nav>
            </Navbar.Collapse> */}
          </Navbar>
        </div>
        <div className="container hidden-xs">
          <Navbar inverse collapseOnSelect fixedTop>
            <Navbar.Header>
              <Navbar.Brand>
                <a href="/" className="navbar-brand">
                  <img
                    alt="Auto Express 79"
                    src={require('../images/logo.png')}
                    className="logo"
                  />
                </a>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              <Navbar.Text pullRight className="phone">Tel. 833 561 5882</Navbar.Text>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    )
  }
}
