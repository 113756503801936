import React from 'react'

import Header from './components/Header'
import Start from './views/Start'

export const Routes = () => {
    return (
        <div>
            <Header />
            <Start />
        </div>
    )
}