import React, { Component } from 'react'

import Contact from '../components/Contact'
import Footer from '../components/Footer'
import Services from '../components/Services'
import Sliders from '../components/Sliders'
import Welcome from '../components/Welcome'

export default class Start extends Component {
  render() {
    return (
      <div>
        <Sliders />
        <Services />
        <Contact />
        <Welcome />
        <Footer />
      </div>
    )
  }
}
