import React, { Component } from 'react'
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps'

import '../styles/map.css'

export default class Map extends Component {
    render() {
        const GoogleMapExample = withGoogleMap(props => (
            <GoogleMap
                defaultCenter={{ lat: 22.259923, lng: -97.8491629 }}
                defaultZoom={15}
                defaultOptions={{
                    styles: [{ "featureType": "all", "elementType": "all", "stylers": [{ "saturation": -100 }, { "gamma": 0.5 }] }]
                }}
            >
                <Marker position={{ lat: 22.259923, lng: -97.8491629 }} />
            </GoogleMap>
        ))
        return (
            <div className="map-container">
                <GoogleMapExample
                    containerElement={<div style={{ height: `400px`, width: '100%' }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
            </div>
        )
    }
}

