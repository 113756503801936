import React, { Component } from 'react'
import '../styles/footer.css'

export default class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="main"> <span>Copyright &copy; {new Date().getFullYear()} <a href="/">Auto Express 79</a><br />Reservados todos los derechos</span><br />Design by <a href="http://www.maderounlimited.com/">MaderoUnlimited.com</a> </div>
            </footer>
        )
    }
}
