import React, { Component } from 'react'

export default class Contact extends Component {
    render() {
        return (
            <div className="container">
                <div className="row welcome">
                    <div className="col-xs-12 col-md-8">
                        <h3>Contactar con Auto Express 79</h3>
                        <p>Puede contactar con nosotros por teléfono, email o directamente por whatsApp</p>
                    </div>
                    <div className="col-xs-12 col-md-4 contact">
                        <p>Teléfono: 833 561 5882</p>
                        <p>e-mail: <a href="mailto:autoexpress79@outlook.com">autoexpress79@outlook.com</a></p>
                        <div className="visible-xs">
                            <p>Por WhatsApp</p>
                            <a className="button" href="https://wa.me/5218333032694?text=Hola">Contactar por WhatsApp</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
