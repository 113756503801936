import '../styles/welcome.css'

import React, { Component } from 'react'
import { ListGroup, ListGroupItem } from 'react-bootstrap'
import Map from './Map';

export default class Welcome extends Component {
    render() {
        return (
            <div className="container">
                <div className="row welcome">
                    <div className="col-xs-12 col-md-8">
                        <h3>Auto Express 79</h3>
                        <p>
                            En <b>Auto Express 79</b> Nuestros principios son la atención al cliente, los precios justos y transparentes y así como la máxima calidad en autopartes. Le orientamos y asesoramos gracias a nuestro personal altamente capacitado y nos ocupamos de forma responsable en darle la asesoría correcta y confiable.
                        </p>
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <div className="box p4">
                            <div className="padding">
                                <div className="wrapper">
                                    <figure className="img-indent"><img src={require('../assets/images/time.png')} alt="" /></figure>
                                    <div className="extra-wrap">
                                        <h3 className="p0">Horarios:</h3>
                                    </div>
                                </div>
                                <p className="p1"></p>
                                <p className="color-1 p0">Lun-Vie: 9:00 AM - 6:00 PM</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row welcome">
                    <div className="col-xs-12 col-md-6">
                        <h3>Nuestros Servicios</h3>
                        <p>
                            Atención a particulares y flotillas
                        </p>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <ListGroup>
                            <ListGroupItem>
                                Diagnóstico por Computadora
                            </ListGroupItem>
                            <ListGroupItem>
                                Revisión de seguridad previa a viaje
                            </ListGroupItem>
                            <ListGroupItem>
                                Resolución de problemas de dirección
                            </ListGroupItem>
                            <ListGroupItem>
                                Cambios de aceite
                            </ListGroupItem>
                            <ListGroupItem>
                                Fallas en el arranque
                            </ListGroupItem>
                            <ListGroupItem>
                                Emisión excesiva de contaminantes
                            </ListGroupItem>

                        </ListGroup>
                    </div>
                </div>
                <div className="row welcome">
                    <div className="col-xs-12">
                        <h3>Nuestra Ubicación</h3>
                        <p>
                            Manuel Acuña 701, Esq. Ángela Peralta
Col. Los Mangos
CD Madero, Tamps
CP 89440
                        </p>
                    </div>
                    <div className="col-xs-12">
                        <Map />
                    </div>
                </div>
            </div>
        )
    }
}
